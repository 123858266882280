import React from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { graphql } from 'gatsby';
import Greenhouse from '../Greenhouse/Greenhouse';
import { customPropTypes } from '../../util';

const JobBoard = ({ data }) => {
  const { id, name, jumpToLinkTitle } = data;
  return <Greenhouse id={id} />;
};

JobBoard.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
  }).isRequired,
});

export default JobBoard;

export const fragments = graphql`
  fragment JobBoard on WpPage_Flexiblelayout_FlexibleChildren_JobBoard {
    id
    jumpToLinkTitle
    name
  }

  fragment JobBoard_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_JobBoard {
    id
    jumpToLinkTitle
    name
  }
`;
