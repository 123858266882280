import React, { Component } from 'react';

import './Greenhouse.scss';

export default class Greenhouse extends Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://boards.greenhouse.io/embed/job_board/js?for=diem';

    script.onload = () => {
      const loadIframe = window.Grnhse.Iframe.load;
      loadIframe();
    };

    this.containerRef.current.appendChild(script);
  }

  shouldComponentUpdate() {
    // React should not update this component, because the Greenhouse
    // script will modify the DOM node with the grnhse_app id to
    // embed the iframe.
    return false;
  }

  render() {
    return (
      <div id={this.props.id} className="JobBoard" ref={this.containerRef}>
        <div id="grnhse_app" />
      </div>
    );
  }
}
